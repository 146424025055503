import { Workbox } from "workbox-window";
var wb;

if ("serviceWorker" in navigator) {
  wb = new Workbox("".concat(process.env.BASE_URL, "service-worker.js"));
  wb.addEventListener("controlling", function () {
    window.location.reload();
  });
  wb.register();
} else {
  wb = null;
}

export default wb;