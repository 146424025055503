import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.slice.js";
import t from "../utils/format";
import e from "../utils/isValidDate";
export default function n() {
  var n = function n(t, e, _n) {
    var s = e.indexOf("YYYY");
    var a = e.indexOf("MM");
    var l = e.indexOf("DD");

    if (s === -1 || a === -1 || l === -1) {
      return null;
    }

    var o = t.split(" ");
    var r = o[0].split(_n);

    if (r.length < 3) {
      return null;
    }

    var c = new Date(parseInt(r[s], 10), parseInt(r[a], 10) - 1, parseInt(r[l], 10));

    if (o.length > 1) {
      var _t = o[1].split(":");

      c.setHours(_t.length > 0 ? parseInt(_t[0], 10) : 0);
      c.setMinutes(_t.length > 1 ? parseInt(_t[1], 10) : 0);
      c.setSeconds(_t.length > 2 ? parseInt(_t[2], 10) : 0);
    }

    return c;
  };

  var s = function s(t, e) {
    var n = e.replace(/Y/g, "y").replace(/M/g, "m").replace(/D/g, "d").replace(/:m/g, ":M").replace(/:mm/g, ":MM").replace(/:S/, ":s").replace(/:SS/, ":ss");
    var s = t.getDate();
    var a = s < 10 ? "0".concat(s) : s;
    var l = t.getMonth() + 1;
    var o = l < 10 ? "0".concat(l) : l;
    var r = "".concat(t.getFullYear()).substr(2);
    var c = t.getFullYear();
    var i = t.getHours() % 12 || 12;
    var g = i < 10 ? "0".concat(i) : i;
    var u = t.getHours();
    var m = u < 10 ? "0".concat(u) : u;
    var d = t.getMinutes();
    var f = d < 10 ? "0".concat(d) : d;
    var p = t.getSeconds();
    var h = p < 10 ? "0".concat(p) : p;
    var $ = {
      H: "".concat(u),
      HH: "".concat(m),
      M: "".concat(d),
      MM: "".concat(f),
      d: "".concat(s),
      dd: "".concat(a),
      h: "".concat(i),
      hh: "".concat(g),
      m: "".concat(l),
      mm: "".concat(o),
      s: "".concat(p),
      ss: "".concat(h),
      yy: "".concat(r),
      yyyy: "".concat(c)
    };
    return n.replace(/d{1,4}|m{1,4}|yy(?:yy)?|([HhMs])\1?|"[^"]*"|'[^']*'/g, function (t) {
      return $[t] ? $[t] : t.slice(1, t.length - 1);
    });
  };

  return {
    validate: function validate(a) {
      if (a.value === "") {
        return {
          meta: {
            date: null
          },
          valid: true
        };
      }

      var l = Object.assign({}, {
        format: a.element && a.element.getAttribute("type") === "date" ? "YYYY-MM-DD" : "MM/DD/YYYY",
        message: ""
      }, a.options);
      var o = a.l10n ? a.l10n.date.default : l.message;
      var r = {
        message: "".concat(o),
        meta: {
          date: null
        },
        valid: false
      };
      var c = l.format.split(" ");
      var i = c.length > 1 ? c[1] : null;
      var g = c.length > 2 ? c[2] : null;
      var u = a.value.split(" ");
      var m = u[0];
      var d = u.length > 1 ? u[1] : null;

      if (c.length !== u.length) {
        return r;
      }

      var f = l.separator || (m.indexOf("/") !== -1 ? "/" : m.indexOf("-") !== -1 ? "-" : m.indexOf(".") !== -1 ? "." : "/");

      if (f === null || m.indexOf(f) === -1) {
        return r;
      }

      var p = m.split(f);
      var h = c[0].split(f);

      if (p.length !== h.length) {
        return r;
      }

      var $ = p[h.indexOf("YYYY")];
      var M = p[h.indexOf("MM")];
      var Y = p[h.indexOf("DD")];

      if (!/^\d+$/.test($) || !/^\d+$/.test(M) || !/^\d+$/.test(Y) || $.length > 4 || M.length > 2 || Y.length > 2) {
        return r;
      }

      var D = parseInt($, 10);
      var x = parseInt(M, 10);
      var y = parseInt(Y, 10);

      if (!e(D, x, y)) {
        return r;
      }

      var I = new Date(D, x - 1, y);

      if (i) {
        var _t2 = d.split(":");

        if (i.split(":").length !== _t2.length) {
          return r;
        }

        var _e = _t2.length > 0 ? _t2[0].length <= 2 && /^\d+$/.test(_t2[0]) ? parseInt(_t2[0], 10) : -1 : 0;

        var _n2 = _t2.length > 1 ? _t2[1].length <= 2 && /^\d+$/.test(_t2[1]) ? parseInt(_t2[1], 10) : -1 : 0;

        var _s = _t2.length > 2 ? _t2[2].length <= 2 && /^\d+$/.test(_t2[2]) ? parseInt(_t2[2], 10) : -1 : 0;

        if (_e === -1 || _n2 === -1 || _s === -1) {
          return r;
        }

        if (_s < 0 || _s > 60) {
          return r;
        }

        if (_e < 0 || _e >= 24 || g && _e > 12) {
          return r;
        }

        if (_n2 < 0 || _n2 > 59) {
          return r;
        }

        I.setHours(_e);
        I.setMinutes(_n2);
        I.setSeconds(_s);
      }

      var O = typeof l.min === "function" ? l.min() : l.min;
      var v = O instanceof Date ? O : O ? n(O, h, f) : I;
      var H = typeof l.max === "function" ? l.max() : l.max;
      var T = H instanceof Date ? H : H ? n(H, h, f) : I;
      var S = O instanceof Date ? s(v, l.format) : O;
      var b = H instanceof Date ? s(T, l.format) : H;

      switch (true) {
        case !!S && !b:
          return {
            message: t(a.l10n ? a.l10n.date.min : o, S),
            meta: {
              date: I
            },
            valid: I.getTime() >= v.getTime()
          };

        case !!b && !S:
          return {
            message: t(a.l10n ? a.l10n.date.max : o, b),
            meta: {
              date: I
            },
            valid: I.getTime() <= T.getTime()
          };

        case !!b && !!S:
          return {
            message: t(a.l10n ? a.l10n.date.range : o, [S, b]),
            meta: {
              date: I
            },
            valid: I.getTime() <= T.getTime() && I.getTime() >= v.getTime()
          };

        default:
          return {
            message: "".concat(o),
            meta: {
              date: I
            },
            valid: true
          };
      }
    }
  };
}