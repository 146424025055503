import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import e from "../utils/fetch";
export default function a() {
  var a = {
    crossDomain: false,
    data: {},
    headers: {},
    method: "GET",
    validKey: "valid"
  };
  return {
    validate: function validate(t) {
      if (t.value === "") {
        return Promise.resolve({
          valid: true
        });
      }

      var s = Object.assign({}, a, t.options);
      var r = s.data;

      if ("function" === typeof s.data) {
        r = s.data.call(this, t);
      }

      if ("string" === typeof r) {
        r = JSON.parse(r);
      }

      r[s.name || t.field] = t.value;
      var o = "function" === typeof s.url ? s.url.call(this, t) : s.url;
      return e(o, {
        crossDomain: s.crossDomain,
        headers: s.headers,
        method: s.method,
        params: r
      }).then(function (e) {
        return Promise.resolve({
          message: e.message,
          meta: e,
          valid: "".concat(e[s.validKey]) === "true"
        });
      }).catch(function (e) {
        return Promise.reject({
          valid: false
        });
      });
    }
  };
}