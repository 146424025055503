import _defineProperty from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _actions, _mutations;

import "core-js/modules/es.object.to-string.js";
import ApiService from "@/core/services/api.service"; // action types

export var UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export var UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";
export var OVERVIEW = "overview";
export var NOTI = "noti";
export var NOTI_ADMIN = "notiAdmin"; // mutation types

export var SET_OVERVIEW = "setOverview";
export var SET_NOTI = "setNoti";
export var SET_NOTI_ADMIN = "setNotiAdmin";
var state = {
  overview: {},
  noti: {},
  noti_admin: {},
  notification: {}
};
var getters = {
  getCurrentNoti: function getCurrentNoti(state) {
    return state.noti;
  },
  getCurrentNotiAdmin: function getCurrentNotiAdmin(state) {
    return state.noti_admin;
  },
  getNotification: function getNotification(state) {
    return state.notification;
  },
  getOverview: function getOverview(state) {
    return state.overview;
  }
};
var actions = (_actions = {}, _defineProperty(_actions, OVERVIEW, function (context) {
  return new Promise(function (resolve, reject) {
    ApiService.post("overview").then(function (_ref) {
      var data = _ref.data;
      context.commit(SET_OVERVIEW, data);
      resolve(data);
    }).catch(function (_ref2) {
      var response = _ref2.response;
      reject(response.data);
    });
  });
}), _defineProperty(_actions, NOTI, function (context) {
  return new Promise(function (resolve, reject) {
    ApiService.get("", "noti").then(function (_ref3) {
      var data = _ref3.data;
      context.commit(SET_NOTI, data);
      resolve(data);
    }).catch(function (_ref4) {
      var response = _ref4.response;
      context.commit(SET_NOTI, {});
      reject(response.data);
    });
  });
}), _defineProperty(_actions, NOTI_ADMIN, function (context) {
  return new Promise(function (resolve, reject) {
    ApiService.get("", "noti_admin").then(function (_ref5) {
      var data = _ref5.data;
      context.commit(SET_NOTI_ADMIN, data);
      resolve(data);
    }).catch(function (_ref6) {
      var response = _ref6.response;
      context.commit(SET_NOTI_ADMIN, {});
      reject(response.data);
    });
  });
}), _actions);
var mutations = (_mutations = {}, _defineProperty(_mutations, SET_OVERVIEW, function (state, overview) {
  state.overview = overview && overview.data;
}), _defineProperty(_mutations, SET_NOTI, function (state, noti) {
  if (noti) {
    state.noti = noti.data;
    state.notification = {
      setting_notification_bar: noti.setting_notification_bar
    };
  }
}), _defineProperty(_mutations, SET_NOTI_ADMIN, function (state, noti) {
  if (noti) {
    state.noti_admin = noti.data;
    state.notification = {
      setting_notification_bar: noti.setting_notification_bar
    };
  }
}), _mutations);
export default {
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
};