"use strict";

var KTLayoutScrolltop = function () {
  // Private properties
  var _element;

  var _object; // Private functions


  var _init = function _init() {
    _object = new KTScrolltop(_element, {
      offset: 300,
      speed: 600
    });
  }; // Public methods


  return {
    init: function init(id) {
      _element = KTUtil.getById(id);

      if (!_element) {
        return;
      } // Initialize


      _init();
    },
    getElement: function getElement() {
      return _element;
    }
  };
}();

export default KTLayoutScrolltop;