import "core-js/modules/es.array.filter.js";
export default function t() {
  return {
    filters: {},
    add: function add(t, e) {
      (this.filters[t] = this.filters[t] || []).push(e);
    },
    clear: function clear() {
      this.filters = {};
    },
    execute: function execute(t, e, i) {
      if (!this.filters[t] || !this.filters[t].length) {
        return e;
      }

      var s = e;
      var r = this.filters[t];
      var l = r.length;

      for (var _t = 0; _t < l; _t++) {
        s = r[_t].apply(s, i);
      }

      return s;
    },
    remove: function remove(t, e) {
      if (this.filters[t]) {
        this.filters[t] = this.filters[t].filter(function (t) {
          return t !== e;
        });
      }
    }
  };
}