import "core-js/modules/es.array.concat.js";
import Vue from "vue";
import axios from "axios";
import { API_URL } from "@/config/index";
import JwtService from "@/core/services/jwt.service";
/**
 * Service to call HTTP request via Axios
 */

var ApiService = {
  init: function init() {
    axios.defaults.baseURL = API_URL;
    axios.defaults.withCredentials = true;
  },
  query: function query(resource, params) {
    return axios.get(resource, params).catch(function (error) {
      // console.log(error);
      throw new Error("[KT] ApiService ".concat(error));
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get: function get(resource) {
    var slug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
    return axios.get("".concat(resource, "/").concat(slug), {
      headers: {
        "x-access-token": JwtService.getToken(),
        "x-refresh-token": JwtService.getRefreshToken()
      }
    }).catch(function (error) {
      // console.log(error);
      throw new Error("[KT] ApiService ".concat(error));
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post: function post(resource, params) {
    return axios.post("".concat(resource), params, {
      headers: {
        "x-access-token": JwtService.getToken(),
        "x-refresh-token": JwtService.getRefreshToken()
      }
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update: function update(resource, slug, params) {
    return Vue.axios.put("".concat(resource, "/").concat(slug), params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put: function put(resource, params) {
    return axios.put("".concat(resource), params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete: function _delete(resource) {
    return axios.delete(resource).catch(function (error) {
      // console.log(error);
      throw new Error("[RWV] ApiService ".concat(error));
    });
  }
};
export default ApiService;