import _toConsumableArray from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import Vue from "vue";
import VueRouter from "vue-router";
import JwtService from "@/core/services/jwt.service";
import routerUser from "./router";
import routerAdmin from "./routerAdmin";
Vue.use(VueRouter); // https://serversideup.net/vuejs-route-permissions-security-and-admin-section/

var router = new VueRouter({
  mode: "history",
  routes: [].concat(_toConsumableArray(routerUser), [{
    path: "/admin",
    name: "admin",
    meta: {
      authRequired: true,
      admin: true
    },
    component: function component() {
      return import("@/view/layout/LayoutAdmin.vue");
    },
    children: routerAdmin
  }])
});

var hasAccess = function hasAccess(name, meta, admin) {
  var storagePer = localStorage.getItem("permissions") && localStorage.getItem("permissions") !== "undefined" ? localStorage.getItem("permissions") : "{permissionRole:[]}";
  var permissions = JSON.parse(storagePer);
  var storageUser = localStorage.getItem("user") && localStorage.getItem("user") !== "undefined" ? localStorage.getItem("user") : "{}";
  var user = JSON.parse(storageUser);

  if (admin) {
    return {
      access: !!user.is_admin,
      is_admin: user.is_admin
    };
  }

  var permissionRole = permissions.permissionRole;
  var find = null;

  if (Array.isArray(name)) {
    for (var i = 0; i < name.length; i++) {
      var n = name[i];
      var access = hasAccess(n, meta, admin);

      if (access.access) {
        return access;
      }
    }

    return {
      access: false,
      is_admin: user.is_admin
    };
  } else {
    find = permissionRole.find(function (x) {
      return x.permissionInfo && x.permissionInfo.permissionName === name;
    });
  }

  if (name === "user") {
    return {
      access: true,
      is_admin: user.is_admin
    };
  }

  if (!user.is_admin) {
    return {
      access: false,
      is_admin: user.is_admin
    };
  }

  if (!find) {
    return {
      access: false,
      is_admin: user.is_admin
    };
  }

  if (meta.readable && find.readableRole) {
    return {
      access: true,
      is_admin: user.is_admin
    };
  }

  if (meta.writeable && find.writeableRole) {
    return {
      access: true,
      is_admin: user.is_admin
    };
  }

  return {
    access: false,
    is_admin: user.is_admin
  };
};

router.beforeEach(function (to, from, next) {
  //A Logged-in user can't go to login page again
  if (to.name === "login" && JwtService.getToken()) {
    router.push({
      name: "dashboard"
    }); //the route requires authentication
  } else if (to.meta.authRequired) {
    if (!JwtService.getToken() || JwtService.tokenIsExpired()) {
      JwtService.destroyToken(); //user not logged in, send them to login page

      router.push({
        name: "login",
        query: {
          to: to.name
        }
      });
    } else {
      var _hasAccess = hasAccess(to.meta.permission, to.meta, to.meta.admin),
          is_admin = _hasAccess.is_admin,
          access = _hasAccess.access;

      if (!is_admin && !access) {
        if (router.history.current.name != "page-not-authorized") {
          router.push({
            name: "page-not-authorized"
          });
          return;
        }
      } else if (is_admin && !access) {
        if (router.history.current.name != "admin-page-not-authorized") {
          router.push({
            name: "admin-page-not-authorized"
          });
        }

        return;
      }
    }
  }

  return next();
});
export default router;