import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.number.constructor.js";

var urljoin = require("url-join");

var bigDecimal = require("js-big-decimal");

var _require = require("lodash-es"),
    orderBy = _require.orderBy;

var BASE_URL_API = process.env.VUE_APP_BASE_URL_API;
var BASE_URL_API_WS = process.env.VUE_APP_BASE_URL_API_WS;
export var CUS_URL = process.env.VUE_APP_CUS_URL;
export var LANDING_URL = process.env.VUE_APP_LANDING_URL;
export var WEBSITE_NAME = process.env.VUE_APP_WEBSITE_NAME;
export var WEBSITE_TITLE = process.env.VUE_APP_WEBSITE_TITLE;
export var TITLE_T_AND_C = process.env.VUE_APP_TITLE_T_AND_C;
export var WEBSITE = "".concat(WEBSITE_NAME, " ").concat(WEBSITE_TITLE);
export var BANK_URL = "".concat(BASE_URL_API, "/images/bank/");
export var IMAGE_URL = "".concat(BASE_URL_API, "/images/");
export var API_URL = "".concat(BASE_URL_API, "/api/");
export var API_URL_WS = "".concat(BASE_URL_API_WS, "/subscriptions");
export var API_URL_GQL = "".concat(BASE_URL_API, "/api-backend");
export var IS_SELLER = process.env.VUE_APP_SELLER == "1";
export var imageUrl = process.env.VUE_APP_IMAGE_URL;
export var imageUrlApi = function imageUrlApi(url) {
  return url && urljoin(imageUrl, url || "");
};
export var banks = [{
  label: "ธนาคารกสิกรไทย - Kasikornbank",
  value: "kbank"
}, {
  label: "ธนาคารกรุงเทพ - Bangkok Bank",
  value: "bbl"
}, {
  label: "ธนาคารไทยพาณิชย์ - Siam Commercial Bank",
  value: "scb"
}, {
  label: "ธนาคารกรุงไทย - Krungthai Bank",
  value: "ktb"
}, {
  label: "ธนาคารกรุงศรีอยุธยา - Bank of Ayudhya (Krungsri)",
  value: "bay"
}, {
  label: "ธนาคารซิตี้แบงค์ - Citibank",
  value: "citi"
}, {
  label: "ธนาคารออมสิน - Government Savings Bank",
  value: "gsb"
}, {
  label: "ธนาคารทหารไทย - TMB Bank",
  value: "tmb"
}, {
  label: "ธนาคารซีไอเอ็มบีไทย - CIMB Thai Bank",
  value: "cimb"
}, {
  label: "ธนาคารอาคารสงเคราะห์ - Government Housing Bank",
  value: "ghb"
}];
export var getBankName = function getBankName(name) {
  var found = banks.find(function (x) {
    return x.value === name;
  });
  return found ? found.label : name;
};
export var encodeQueryData = function encodeQueryData(data) {
  var ret = [];

  for (var d in data) {
    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
  }

  return ret.join("&");
};
export var getPriceLogistics = function getPriceLogistics(logistics) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$kg = _ref.kg,
      kg = _ref$kg === void 0 ? 0 : _ref$kg;

  if (!logistics) {
    return 0;
  }

  var num = kg;
  var newLogistics = JSON.parse(JSON.stringify(logistics));
  newLogistics.logisticsPriceList = orderBy(logistics.logisticsPriceList, ["min"], ["desc"]);

  for (var i = 0; i < (newLogistics.logisticsPriceList || []).length; i += 1) {
    var p = newLogistics.logisticsPriceList[i];

    if (num >= p.min) {
      return p.price;
    }
  }

  return 0;
};
export var calculateCube = function calculateCube(data) {
  var amount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

  if (!data) {
    return 0;
  }

  var val = (data.width || 0) * (data.height || 0) * (data.long || 0) / 1000000;
  val *= amount;
  return bigDecimal.round(Math.floor(val * 10000) / 10000, 4);
};
export var calculateWeight = function calculateWeight(weight) {
  var amount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

  if (!weight) {
    return 0;
  }

  var val = weight * amount;
  return bigDecimal.round(Math.floor(val * 100) / 100, 2);
};
export var multipy = function multipy(x, y) {
  return Number(bigDecimal.round(bigDecimal.multiply(x, y), 2, bigDecimal.RoundingModes.DOWN));
};