import _defineProperty from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _actions;

import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import ApiService from "../api.service"; // action types

export var UPDATE_PAYSHIPPING = "updatePayshipping";
export var PRINT_BILL = "printBill";
export var PRINT_BILL_SELLER = "printBillSeller";
export var PRINT_BILL_INVOICE = "printBillInvoice";
export var PRINT_BILL_ADDRESS = "printBillAddress"; // mutation types

export var SET_PAYSHIPPING = "setPayshipping";
var state = {
  items: localStorage.getItem("payshippingItems") ? JSON.parse(localStorage.getItem("payshippingItems")) : []
};
var getters = {
  getPayshippingItems: function getPayshippingItems(state) {
    return state.items;
  }
};
var actions = (_actions = {}, _defineProperty(_actions, UPDATE_PAYSHIPPING, function (context, payload) {
  context.commit(SET_PAYSHIPPING, payload);
}), _defineProperty(_actions, PRINT_BILL, function (context, _ref) {
  var label = _ref.label,
      id = _ref.id;
  return new Promise(function (resolve, reject) {
    ApiService.get("bill", "deliveryprint?label=".concat(label, "&id=").concat(id)).then(function (_ref2) {
      var data = _ref2.data;
      resolve(data);
    }).catch(function (_ref3) {
      var response = _ref3.response;
      reject(response.data);
    });
  });
}), _defineProperty(_actions, PRINT_BILL_SELLER, function (context, _ref4) {
  var label = _ref4.label,
      id = _ref4.id;
  return new Promise(function (resolve, reject) {
    ApiService.get("seller", "deliveryprint?label=".concat(label, "&id=").concat(id)).then(function (_ref5) {
      var data = _ref5.data;
      resolve(data);
    }).catch(function (_ref6) {
      var response = _ref6.response;
      reject(response.data);
    });
  });
}), _defineProperty(_actions, PRINT_BILL_INVOICE, function (context, _ref7) {
  var id = _ref7.id;
  return new Promise(function (resolve, reject) {
    ApiService.get("document", "invoice?id=".concat(id)).then(function (_ref8) {
      var data = _ref8.data;
      resolve(data);
    }).catch(function (_ref9) {
      var response = _ref9.response;
      reject(response.data);
    });
  });
}), _defineProperty(_actions, PRINT_BILL_ADDRESS, function (context, _ref10) {
  var ids = _ref10.ids;
  return new Promise(function (resolve, reject) {
    ApiService.get("bill", "address?ids=".concat(ids)).then(function (_ref11) {
      var data = _ref11.data;
      resolve(data);
    }).catch(function (_ref12) {
      var response = _ref12.response;
      reject(response.data);
    });
  });
}), _actions);

var mutations = _defineProperty({}, SET_PAYSHIPPING, function (state, items) {
  localStorage.setItem("payshippingItems", JSON.stringify(items));
  state.items = items;
});

export default {
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
};