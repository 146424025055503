import _defineProperty from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/defineProperty";
// action types
export var UPDATE_CHECKOUT = "updateCheckoutSeller"; // mutation types

export var SET_CHECKOUT = "setCheckoutSeller";
var state = {
  seller_checkout: {},
  seller_vendor: {}
};
var getters = {
  seller_checkout: function seller_checkout(state) {
    return state.seller_checkout;
  },
  seller_vendor: function seller_vendor(state) {
    return state.seller_vendor;
  }
};

var actions = _defineProperty({}, UPDATE_CHECKOUT, function (context, payload) {
  context.commit(SET_CHECKOUT, payload);
});

var mutations = _defineProperty({}, SET_CHECKOUT, function (state, _ref) {
  var selected = _ref.selected,
      vendor = _ref.vendor;
  state.seller_checkout = selected;
  state.seller_vendor = vendor;
});

export default {
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
};