import "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/core-js/modules/es.array.iterator.js";
import "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/core-js/modules/es.promise.js";
import "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/core-js/modules/es.object.assign.js";
import "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/core-js/modules/es.promise.finally.js";
import Vue from "vue";
import frag from "vue-frag";
import Message from "vue-m-message";
import VueRouter from "vue-router";
import App from "./App.vue";
import router from "./router/index";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { createProvider } from "@/vue-apollo";
import { VERIFY_AUTH } from "./core/services/store/auth.module";
import Viewer from "v-viewer";
import VueThailandAddress from "vue-thailand-address";
import VueChatScroll from "vue-chat-scroll";
import * as Sentry from "@sentry/vue";
import wb from "./registerServiceWorker";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "viewerjs/dist/viewer.css";
import "vue-m-message/dist/index.css";
import "vue-select/dist/vue-select.css"; // import devtools from "@vue/devtools";
// if (process.env.NODE_ENV === "development") {
//   devtools.connect("http://localhost:8098");
// }

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue: Vue,
    dsn: "https://26379def152a7254f89d1ea5ce95f928@o602048.ingest.us.sentry.io/4506901420310529",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

Vue.prototype.$workbox = wb;
Vue.use(Viewer);
Vue.use(VueChatScroll);
Vue.use(Message);
Vue.use(VueThailandAddress);
Vue.directive("frag", frag);
Viewer.setDefaults({
  toolbar: false,
  movable: false,
  transition: false
});
Vue.config.productionTip = true;
Vue.config.performance = true; // Global 3rd party plugins
// import "popper.js";
// import "tooltip.js";

import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS; // Vue 3rd party plugins

import i18n from "@/core/plugins/vue-i18n"; // import "@/core/plugins/portal-vue";

import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar"; // import "@/core/plugins/highlight-js";

import "@/core/plugins/inline-svg"; // import "@/core/plugins/apexcharts";
// import "@/core/plugins/treeselect";

import "@/core/plugins/metronic"; // import "@mdi/font/css/materialdesignicons.css";

import "@/core/plugins/formvalidation"; // API service init

ApiService.init(); // Remove this to disable mock API
// MockService.init();

router.beforeEach(function (to, from, next) {
  // Ensure we checked auth before each page load.
  if (from === VueRouter.START_LOCATION) {
    store.dispatch(VERIFY_AUTH);
  }

  next(); // reset config to initial state

  store.dispatch(RESET_LAYOUT_CONFIG); // Scroll page to top on every route change
  // setTimeout(() => {
  //   window.scrollTo(0, 0);
  // }, 100);
});
new Vue({
  router: router,
  store: store,
  i18n: i18n,
  apolloProvider: createProvider(),
  render: function render(h) {
    return h(App);
  }
}).$mount("#app");