import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.exec.js";
export default function r(r, e) {
  var t = Array.isArray(e) ? e : [e];
  var a = r;
  t.forEach(function (r) {
    a = a.replace("%s", r);
  });
  return a;
}