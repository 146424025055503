import _defineProperty from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/defineProperty";
// action types
export var UPDATE_CHECKOUT = "updateCheckout"; // mutation types

export var SET_CHECKOUT = "setCheckout";
var state = {
  checkout: {},
  vendor: {},
  rateOrder: 0,
  serviceCube: 0,
  serviceKg: 0
};
var getters = {
  checkout: function checkout(state) {
    return state.checkout;
  },
  vendor: function vendor(state) {
    return state.vendor;
  },
  rateOrder: function rateOrder(state) {
    return state.rateOrder;
  },
  serviceCube: function serviceCube(state) {
    return state.serviceCube;
  },
  serviceKg: function serviceKg(state) {
    return state.serviceKg;
  }
};

var actions = _defineProperty({}, UPDATE_CHECKOUT, function (context, payload) {
  context.commit(SET_CHECKOUT, payload);
});

var mutations = _defineProperty({}, SET_CHECKOUT, function (state, _ref) {
  var selected = _ref.selected,
      vendor = _ref.vendor,
      rateOrder = _ref.rateOrder,
      serviceCube = _ref.serviceCube,
      serviceKg = _ref.serviceKg;
  state.checkout = selected;
  state.vendor = vendor;
  state.rateOrder = rateOrder;
  state.serviceCube = serviceCube;
  state.serviceKg = serviceKg;
});

export default {
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
};