import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import Vue from "vue";
import VueI18n from "vue-i18n"; // Localisation language list

import { locale as en } from "@/core/config/i18n/en";
import { locale as ch } from "@/core/config/i18n/ch";
import { locale as es } from "@/core/config/i18n/es";
import { locale as jp } from "@/core/config/i18n/jp";
import { locale as de } from "@/core/config/i18n/de";
import { locale as fr } from "@/core/config/i18n/fr";
Vue.use(VueI18n);
var messages = {};
messages = _objectSpread(_objectSpread({}, messages), {}, {
  en: en,
  ch: ch,
  es: es,
  jp: jp,
  de: de,
  fr: fr
}); // get current selected language

var lang = localStorage.getItem("language") || "en"; // Create VueI18n instance with options

var i18n = new VueI18n({
  locale: lang,
  // set locale
  messages: messages // set locale messages

});
export default i18n;