import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import checkout from "./checkout.module";
import seller_checkout from "./seller_checkout.module";
import payshipping from "./payshipping.module";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    auth: auth,
    htmlClass: htmlClass,
    config: config,
    breadcrumbs: breadcrumbs,
    profile: profile,
    checkout: checkout,
    seller_checkout: seller_checkout,
    payshipping: payshipping
  }
});