import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
export default function s() {
  return {
    fns: {},
    clear: function clear() {
      this.fns = {};
    },
    emit: function emit(s) {
      for (var _len = arguments.length, f = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        f[_key - 1] = arguments[_key];
      }

      (this.fns[s] || []).map(function (s) {
        return s.apply(s, f);
      });
    },
    off: function off(s, f) {
      if (this.fns[s]) {
        var n = this.fns[s].indexOf(f);

        if (n >= 0) {
          this.fns[s].splice(n, 1);
        }
      }
    },
    on: function on(s, f) {
      (this.fns[s] = this.fns[s] || []).push(f);
    }
  };
}