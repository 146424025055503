import "core-js/modules/es.string.trim.js";
import e from "../utils/format";
export default function t() {
  var t = function t(e) {
    var t = e.length;

    for (var s = e.length - 1; s >= 0; s--) {
      var n = e.charCodeAt(s);

      if (n > 127 && n <= 2047) {
        t++;
      } else if (n > 2047 && n <= 65535) {
        t += 2;
      }

      if (n >= 56320 && n <= 57343) {
        s--;
      }
    }

    return "".concat(t);
  };

  return {
    validate: function validate(s) {
      var n = Object.assign({}, {
        message: "",
        trim: false,
        utf8Bytes: false
      }, s.options);
      var a = n.trim === true || "".concat(n.trim) === "true" ? s.value.trim() : s.value;

      if (a === "") {
        return {
          valid: true
        };
      }

      var r = n.min ? "".concat(n.min) : "";
      var l = n.max ? "".concat(n.max) : "";
      var i = n.utf8Bytes ? t(a) : a.length;
      var g = true;
      var m = s.l10n ? n.message || s.l10n.stringLength.default : n.message;

      if (r && i < parseInt(r, 10) || l && i > parseInt(l, 10)) {
        g = false;
      }

      switch (true) {
        case !!r && !!l:
          m = e(s.l10n ? n.message || s.l10n.stringLength.between : n.message, [r, l]);
          break;

        case !!r:
          m = e(s.l10n ? n.message || s.l10n.stringLength.more : n.message, "".concat(parseInt(r, 10) - 1));
          break;

        case !!l:
          m = e(s.l10n ? n.message || s.l10n.stringLength.less : n.message, "".concat(parseInt(l, 10) + 1));
          break;

        default:
          break;
      }

      return {
        message: m,
        valid: g
      };
    }
  };
}