import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
export default function e(e, t) {
  var n = function n(e) {
    return Object.keys(e).map(function (t) {
      return "".concat(encodeURIComponent(t), "=").concat(encodeURIComponent(e[t]));
    }).join("&");
  };

  return new Promise(function (o, s) {
    var d = Object.assign({}, {
      crossDomain: false,
      headers: {},
      method: "GET",
      params: {}
    }, t);
    var a = Object.keys(d.params).map(function (e) {
      return "".concat(encodeURIComponent(e), "=").concat(encodeURIComponent(d.params[e]));
    }).join("&");
    var r = e.indexOf("?");
    var c = "GET" === d.method ? "".concat(e).concat(r ? "?" : "&").concat(a) : e;

    if (d.crossDomain) {
      var _e = document.createElement("script");

      var _t = "___fetch".concat(Date.now(), "___");

      window[_t] = function (e) {
        delete window[_t];
        o(e);
      };

      _e.src = "".concat(c).concat(r ? "&" : "?", "callback=").concat(_t);
      _e.async = true;

      _e.addEventListener("load", function () {
        _e.parentNode.removeChild(_e);
      });

      _e.addEventListener("error", function () {
        return s;
      });

      document.head.appendChild(_e);
    } else {
      var _e2 = new XMLHttpRequest();

      _e2.open(d.method, c);

      _e2.setRequestHeader("X-Requested-With", "XMLHttpRequest");

      if ("POST" === d.method) {
        _e2.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      }

      Object.keys(d.headers).forEach(function (t) {
        return _e2.setRequestHeader(t, d.headers[t]);
      });

      _e2.addEventListener("load", function () {
        o(JSON.parse(this.responseText));
      });

      _e2.addEventListener("error", function () {
        return s;
      });

      _e2.send(n(d.params));
    }
  });
}