import "core-js/modules/es.string.trim.js";
export default function t() {
  return {
    validate: function validate(t) {
      var n = !!t.options && !!t.options.trim;
      var o = t.value;
      return {
        valid: !n && o !== "" || n && o !== "" && o.trim() !== ""
      };
    }
  };
}