import _objectWithoutProperties from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _actions, _mutations;

import "core-js/modules/es.object.to-string.js";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service"; // action types

export var VERIFY_AUTH = "verifyAuth";
export var VERIFY_EMAIL_AUTH = "auth/verify";
export var LOGIN = "login";
export var LOGOUT = "logout";
export var REGISTER = "register";
export var TERM_AND_CONDITION = "termAndCondition";
export var UPDATE_PASSWORD = "updateUser";
export var RESET_PASSWORD = "resetPassword";
export var RESEND_MAIL = "resendMail";
export var ACCOUNT = "account"; // mutation types

export var PURGE_AUTH = "logOut";
export var SET_AUTH = "setAuth";
export var SET_USER = "setUser";
export var SET_PASSWORD = "setPassword";
export var SET_ERROR = "setError";
export var SET_ERROR_REGISTER = "setErrorRegister";
var state = {
  errors: null,
  errorRegister: [],
  user: localStorage.getItem("user") && localStorage.getItem("user") != "undefined" ? JSON.parse(localStorage.getItem("user")) : {},
  permissions: localStorage.getItem("permissions") && localStorage.getItem("permissions") != "undefined" ? JSON.parse(localStorage.getItem("permissions")) : {},
  is_admin: localStorage.getItem("user") && localStorage.getItem("user") != "undefined" ? JSON.parse(localStorage.getItem("user")).is_admin : false,
  isAuthenticated: !!JwtService.getToken()
};
var getters = {
  currentUser: function currentUser(state) {
    return state.user;
  },
  currentPermission: function currentPermission(state) {
    return state.permissions;
  },
  getIsAdmin: function getIsAdmin(state) {
    return state.is_admin;
  },
  isAuthenticated: function isAuthenticated(state) {
    return state.isAuthenticated;
  }
};
var actions = (_actions = {}, _defineProperty(_actions, LOGIN, function (context, credentials) {
  return new Promise(function (resolve, reject) {
    ApiService.post("do_login", credentials).then(function (_ref) {
      var data = _ref.data;
      var isSuccess = data.isSuccess,
          message = data.message;

      if (!isSuccess) {
        context.commit(SET_ERROR, [message]);
        return;
      }

      context.commit(SET_AUTH, data.data);
      resolve(data);
    }).catch(function (_ref2) {
      var response = _ref2.response;
      context.commit(SET_ERROR, response.data.errors);
      reject(response.data);
    });
  });
}), _defineProperty(_actions, LOGOUT, function (context) {
  return new Promise(function (resolve) {
    ApiService.get("", "do_logout").then(function (_ref3) {
      var data = _ref3.data;
      context.commit(PURGE_AUTH);
      resolve(data);
    }).catch(function (_ref4) {
      var response = _ref4.response;
      context.commit(SET_ERROR, response.data.errors);
    });
  });
}), _defineProperty(_actions, REGISTER, function (context, credentials) {
  return new Promise(function (resolve, reject) {
    ApiService.post("signup", credentials).then(function (_ref5) {
      var data = _ref5.data;
      context.commit(SET_AUTH, data);
      resolve(data);
    }).catch(function (_ref6) {
      var response = _ref6.response;
      context.commit(SET_ERROR_REGISTER, response.data.error_list);
      reject(response.data);
    });
  });
}), _defineProperty(_actions, TERM_AND_CONDITION, function () {
  return new Promise(function (resolve, reject) {
    ApiService.get("", "term_and_condition").then(function (_ref7) {
      var data = _ref7.data;
      resolve(data);
    }).catch(function (_ref8) {
      var response = _ref8.response;
      reject(response.data);
    });
  });
}), _defineProperty(_actions, VERIFY_EMAIL_AUTH, function (context, credentials) {
  return new Promise(function (resolve, reject) {
    ApiService.post("auth/verifyRegister", credentials).then(function (_ref9) {
      var data = _ref9.data;
      resolve(data);
    }).catch(function (_ref10) {
      var response = _ref10.response;
      reject(response.data);
    });
  });
}), _defineProperty(_actions, VERIFY_AUTH, function (context) {
  if (JwtService.getToken()) {
    ApiService.get("", "verify").then(function (_ref11) {
      var data = _ref11.data;
      var isSuccess = data.isSuccess,
          message = data.message;
      console.log("isSuccess", isSuccess);

      if (!isSuccess) {
        context.commit(SET_ERROR, [message]);
        return;
      }

      context.commit(SET_AUTH, data.data);
    }).catch(function () {
      context.dispatch(LOGOUT);
    });
  } else {
    context.commit(PURGE_AUTH);
  }
}), _defineProperty(_actions, UPDATE_PASSWORD, function (context, payload) {
  var password = payload;
  return ApiService.put("password", password).then(function (_ref12) {
    var data = _ref12.data;
    context.commit(SET_PASSWORD, data);
    return data;
  });
}), _defineProperty(_actions, RESET_PASSWORD, function (context, payload) {
  return new Promise(function (resolve, reject) {
    ApiService.post("do_reset_password", payload).then(function (_ref13) {
      var data = _ref13.data;
      context.commit(SET_PASSWORD, data);
      resolve(data);
    }).catch(function (_ref14) {
      var response = _ref14.response;
      reject(response.data);
    });
  });
}), _defineProperty(_actions, RESEND_MAIL, function (context, payload) {
  return new Promise(function (resolve, reject) {
    ApiService.post("resend", payload).then(function (_ref15) {
      var data = _ref15.data;
      resolve(data);
    }).catch(function (_ref16) {
      var response = _ref16.response;
      reject(response.data);
    });
  });
}), _defineProperty(_actions, ACCOUNT, function (context) {
  return new Promise(function (resolve, reject) {
    ApiService.get("", "account").then(function (_ref17) {
      var data = _ref17.data;
      console.log("data", data);
      var isSuccess = data.isSuccess,
          message = data.message;

      if (!isSuccess) {
        context.commit(SET_ERROR, [message]);
        return;
      }

      context.commit(SET_USER, data.data);
      resolve(data);
    }).catch(function (_ref18) {
      var response = _ref18.response;
      console.log("response", response);

      if (response) {
        context.commit(SET_ERROR, response.data.errors);
      }

      context.dispatch(LOGOUT);
      reject(response === null || response === void 0 ? void 0 : response.data);
    });
  });
}), _actions);
var mutations = (_mutations = {}, _defineProperty(_mutations, SET_ERROR, function (state, error) {
  state.errors = error;
}), _defineProperty(_mutations, SET_ERROR_REGISTER, function (state, error) {
  state.errorRegister = error;
}), _defineProperty(_mutations, SET_AUTH, function (state, user) {
  var role = user.role,
      newprops = _objectWithoutProperties(user, ["role"]);

  localStorage.setItem("user", JSON.stringify(newprops));
  localStorage.setItem("permissions", JSON.stringify(role));
  state.isAuthenticated = true;
  state.user = newprops;
  state.permissions = role;
  state.is_admin = newprops.is_admin;
  state.errors = {};
  JwtService.saveToken(state.user.token);
  JwtService.saveRefreshToken(state.user.refreshToken);
}), _defineProperty(_mutations, SET_USER, function (state, user) {
  var role = user.role,
      newprops = _objectWithoutProperties(user, ["role"]);

  localStorage.setItem("user", JSON.stringify(newprops));
  localStorage.setItem("permissions", JSON.stringify(role));
  state.isAuthenticated = true;
  state.user = newprops;
  state.permissions = role;
  state.is_admin = newprops.is_admin;
  state.errors = {};
}), _defineProperty(_mutations, SET_PASSWORD, function (state, password) {
  state.user.password = password;
}), _defineProperty(_mutations, PURGE_AUTH, function (state) {
  state.isAuthenticated = false;
  state.user = {};
  state.permissions = {};
  state.errors = {};
  JwtService.destroyToken();
}), _mutations);
export default {
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
};