export default function e() {
  return {
    validate: function validate(e) {
      if (e.value === "") {
        return {
          valid: true
        };
      }

      var a = Object.assign({}, {
        case: "lower"
      }, e.options);
      var s = (a.case || "lower").toLowerCase();
      return {
        message: a.message || (e.l10n ? "upper" === s ? e.l10n.stringCase.upper : e.l10n.stringCase.default : a.message),
        valid: "upper" === s ? e.value === e.value.toUpperCase() : e.value === e.value.toLowerCase()
      };
    }
  };
}