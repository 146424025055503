import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: "/admin",
  name: "admin_dashboard",
  meta: {
    authRequired: true,
    admin: true
  },
  component: function component() {
    return import(
    /* webpackChunkName: "dashboard-admin" */
    "@/view/pages/dashboard/DashboardAdmin.vue");
  }
}, {
  name: "admin_user",
  path: "/admin/users",
  meta: {
    authRequired: true,
    permission: "admin_user",
    readable: true
  },
  component: function component() {
    return import("@/view/pages/admin/users/UserList.vue");
  }
}, {
  name: "admin_profile",
  path: "/admin/profile",
  meta: {
    authRequired: true,
    permission: "user"
  },
  component: function component() {
    return import("@/view/pages/profile/Profile.vue");
  }
}, {
  name: "adminChangePassword",
  path: "/admin/password-management",
  meta: {
    authRequired: true,
    permission: "user"
  },
  component: function component() {
    return import("@/view/pages/profile/ChangePassword.vue");
  }
}, {
  name: "admin_user_create",
  path: "/admin/users/create",
  meta: {
    authRequired: true,
    permission: "admin_user",
    writeable: true
  },
  component: function component() {
    return import("@/view/pages/admin/users/UserCreate.vue");
  }
}, {
  name: "admin_user_detail",
  path: "/admin/users/:id",
  meta: {
    authRequired: true,
    permission: "admin_user",
    readable: true
  },
  component: function component() {
    return import("@/view/pages/admin/users/User.vue");
  }
}, {
  name: "admin_forwarder",
  path: "/admin/forwarder",
  meta: {
    authRequired: true,
    permission: "admin_payshipping",
    readable: true
  },
  component: function component() {
    return import("@/view/pages/admin/transport/TransportList.vue");
  }
}, {
  name: "admin_forwarder_create",
  path: "/admin/forwarder/create",
  meta: {
    authRequired: true,
    permission: "admin_payshipping",
    readable: true
  },
  component: function component() {
    return import("@/view/pages/admin/transport/TransportCreate.vue");
  }
}, {
  name: "admin_forwarder_detail",
  path: "/admin/forwarder/:id",
  meta: {
    authRequired: true,
    permission: "admin_payshipping",
    writeable: true
  },
  component: function component() {
    return import("@/view/pages/admin/transport/TransportDetail.vue");
  }
}, {
  path: "/admin/shop",
  name: "admin_shop",
  component: function component() {
    return import("@/view/pages/admin/shop/Shop.vue");
  },
  children: [{
    name: "admin_order_list",
    path: "/admin/shop/order",
    meta: {
      authRequired: true,
      permission: "admin_order",
      readable: true
    },
    component: function component() {
      return import("@/view/pages/admin/shop/OrderList.vue");
    }
  }, {
    name: "admin_order_detail",
    path: "/admin/shop/order/:id",
    meta: {
      authRequired: true,
      permission: "admin_order",
      readable: true
    },
    component: function component() {
      return import("@/view/pages/admin/shop/OrderDetail.vue");
    }
  }, {
    name: "admin_product",
    path: "/admin/shop/search/:url",
    meta: {
      authRequired: true
    },
    component: function component() {
      return import("@/view/pages/product/Product.vue");
    }
  }]
}, {
  name: "admin_wallet",
  path: "/admin/wallet",
  meta: {
    authRequired: true,
    permission: ["admin_balance", "admin_topup"],
    readable: true
  },
  component: function component() {
    return import("@/view/pages/admin/wallet/Wallet.vue");
  }
}, {
  name: "admin_topup",
  path: "/admin/wallet/topup/:id",
  meta: {
    authRequired: true,
    permission: "admin_topup",
    readable: true
  },
  component: function component() {
    return import("@/view/pages/admin/wallet/Topup.vue");
  }
}, {
  name: "admin_withdraw",
  path: "/admin/wallet/withdraw/:id",
  meta: {
    authRequired: true,
    permission: "admin_balance",
    readable: true
  },
  component: function component() {
    return import("@/view/pages/admin/wallet/Withdraw.vue");
  }
}, {
  name: "admin_shippay_bill",
  path: "/admin/shippay/bill",
  meta: {
    authRequired: true,
    permission: "admin_payshipping",
    readable: true
  },
  component: function component() {
    return import("@/view/pages/admin/paybill/PayBillList.vue");
  }
}, {
  name: "admin_shippay_bill_detail",
  path: "/admin/shippay/bill/:id",
  meta: {
    authRequired: true,
    permission: "admin_payshipping",
    readable: true
  },
  component: function component() {
    return import("@/view/pages/admin/paybill/PayBillDetail.vue");
  }
}, {
  name: "admin_payment_list",
  path: "/admin/payments",
  meta: {
    authRequired: true,
    permission: "admin_payment",
    readable: true
  },
  component: function component() {
    return import("@/view/pages/admin/payments/PaymentList.vue");
  }
}, {
  name: "admin_payment",
  path: "/admin/payments/:id",
  meta: {
    authRequired: true,
    permission: "admin_payment",
    readable: true
  },
  component: function component() {
    return import("@/view/pages/admin/payments/Payment.vue");
  }
}, {
  name: "admin_chat",
  path: "/admin/chat",
  meta: {
    authRequired: true,
    permission: "admin_chat",
    readable: true
  },
  component: function component() {
    return import("@/view/pages/admin/chat/Chat.vue");
  }
}, {
  name: "admin_lot_list",
  path: "/admin/lot",
  meta: {
    authRequired: true,
    permission: "admin_lot",
    readable: true
  },
  component: function component() {
    return import("@/view/pages/admin/lot/LotList.vue");
  }
}, {
  name: "admin_lot_create",
  path: "/admin/lot/create",
  meta: {
    authRequired: true,
    permission: "admin_lot",
    readable: true
  },
  component: function component() {
    return import("@/view/pages/admin/lot/LotCreate.vue");
  }
}, {
  name: "admin_lot",
  path: "/admin/lot/:id",
  meta: {
    authRequired: true,
    permission: "admin_lot",
    readable: true
  },
  component: function component() {
    return import("@/view/pages/admin/lot/Lot.vue");
  }
}, {
  name: "setting",
  path: "/admin/setting",
  component: function component() {
    return import("@/view/pages/admin/settings/SettingLayout.vue");
  },
  children: [{
    name: "setting_rate",
    path: "/admin/setting/exchange-rate",
    meta: {
      authRequired: true,
      permission: "admin_exchange_rate",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/SettingRate.vue");
    }
  }, {
    name: "setting_address",
    path: "/admin/setting/address",
    meta: {
      authRequired: true,
      permission: "admin_address",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/SettingAddress.vue");
    }
  }, {
    name: "setting_address_china",
    path: "/admin/setting/china",
    meta: {
      authRequired: true,
      permission: "admin_address_china",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/SettingAddressChina.vue");
    }
  }, {
    name: "setting_thai_shipping_list",
    path: "/admin/setting/thai-shipping",
    meta: {
      authRequired: true,
      permission: "admin_delivery",
      readable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/thai_shipping/ThaiShippingList.vue");
    }
  }, {
    name: "setting_thai_shipping_create",
    path: "/admin/setting/thai-shipping/create",
    meta: {
      authRequired: true,
      permission: "admin_delivery",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/thai_shipping/ThaiShippingCreate.vue");
    }
  }, {
    name: "setting_thai_shipping",
    path: "/admin/setting/thai-shipping/:id",
    meta: {
      authRequired: true,
      permission: "admin_delivery",
      readable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/thai_shipping/ThaiShipping.vue");
    }
  }, {
    name: "setting_bank",
    path: "/admin/setting/bank",
    meta: {
      authRequired: true,
      permission: "admin_bank",
      readable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/bank/BankList.vue");
    }
  }, {
    name: "setting_bank_create",
    path: "/admin/setting/bank/create",
    meta: {
      authRequired: true,
      permission: "admin_bank",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/bank/BankCreate.vue");
    }
  }, {
    name: "china_to_thai_list",
    path: "/admin/setting/china-to-thai",
    meta: {
      authRequired: true,
      permission: "admin_chat",
      readable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/shipping_rate/ShippingRateList.vue");
    }
  }, {
    name: "china_to_thai",
    path: "/admin/setting/china-to-thai/create",
    meta: {
      authRequired: true,
      permission: "admin_china_thai",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/shipping_rate/ShippingRateCreate.vue");
    }
  }, {
    name: "china_to_thai_detail",
    path: "/admin/setting/china-to-thai/:id",
    meta: {
      authRequired: true,
      permission: "admin_china_thai",
      readable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/shipping_rate/ShippingRate.vue");
    }
  }, {
    name: "admin_news",
    path: "/admin/setting/news",
    meta: {
      authRequired: true,
      permission: "setting_notification_bar",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/SettingNews.vue");
    }
  }, {
    name: "admin_news",
    path: "/admin/setting/news_list",
    meta: {
      authRequired: true,
      permission: "setting_notification_bar",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/news/NewsList.vue");
    }
  }, {
    name: "role_list",
    path: "/admin/setting/role",
    meta: {
      authRequired: true,
      permission: "admin_role",
      readable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/role/RoleList.vue");
    }
  }, {
    name: "admin_notification",
    path: "/admin/setting/notification",
    meta: {
      authRequired: true,
      permission: "admin_notification",
      readable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/notification/SettingNotification.vue");
    }
  }, {
    name: "admin_dropship",
    path: "/admin/setting/dropship",
    meta: {
      authRequired: true,
      permission: "admin_china_thai",
      readable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/SettingServiceDropShip.vue");
    }
  }, {
    name: "admin_minimum_payshipping",
    path: "/admin/setting/minimum-payshipping",
    meta: {
      authRequired: true,
      permission: "admin_minimum_payshipping",
      readable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/SettingMiniumPayshipping.vue");
    }
  }, {
    name: "admin_tracking_warehouse_list",
    path: "/admin/setting/tracking-warehouse",
    meta: {
      authRequired: true,
      permission: "admin_tracking_warehouse",
      readable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/tracking_warehouse/TrackingWarehouseList.vue");
    }
  }, {
    name: "admin_tracking_warehouse_create",
    path: "/admin/setting/tracking-warehouse/create",
    meta: {
      authRequired: true,
      permission: "admin_tracking_warehouse",
      readable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/tracking_warehouse/TrackingWarehouseCreate.vue");
    }
  }, {
    name: "admin_tracking_warehouse",
    path: "/admin/setting/tracking-warehouse/:id",
    meta: {
      authRequired: true,
      permission: "admin_tracking_warehouse",
      readable: true
    },
    component: function component() {
      return import("@/view/pages/admin/settings/tracking_warehouse/TrackingWarehouse.vue");
    }
  }]
}, {
  name: "admin_seller",
  path: "/admin/seller",
  component: function component() {
    return import("@/view/pages/admin/seller/SellerLayout.vue");
  },
  children: [{
    name: "admin_seller_category_list",
    path: "/admin/seller/category",
    meta: {
      authRequired: true,
      permission: "admin_seller",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/seller/category/CategoryList.vue");
    }
  }, {
    name: "admin_seller_category_new",
    path: "/admin/seller/category/new",
    meta: {
      authRequired: true,
      permission: "admin_seller",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/seller/category/CategoryCreate.vue");
    }
  }, {
    name: "admin_seller_category",
    path: "/admin/seller/category/:id",
    meta: {
      authRequired: true,
      permission: "admin_seller",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/seller/category/Category.vue");
    }
  }, {
    name: "admin_seller_forwarder_list",
    path: "/admin/seller/forwarder",
    meta: {
      authRequired: true,
      permission: "admin_seller",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/seller/forwarder/ForwarderList.vue");
    }
  }, {
    name: "admin_seller_forwarder_new",
    path: "/admin/seller/forwarder/new",
    meta: {
      authRequired: true,
      permission: "admin_seller",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/seller/forwarder/ForwarderCreate.vue");
    }
  }, {
    name: "admin_seller_forwarder",
    path: "/admin/seller/forwarder/:id",
    meta: {
      authRequired: true,
      permission: "admin_seller",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/seller/forwarder/Forwarder.vue");
    }
  }, {
    name: "admin_seller_sale_list",
    path: "/admin/seller/sale",
    meta: {
      authRequired: true,
      permission: "admin_seller",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/seller/sale/SellerOrderList.vue");
    }
  }, {
    name: "admin_seller_sale",
    path: "/admin/seller/sale/:id",
    meta: {
      authRequired: true,
      permission: "admin_seller",
      writeable: true
    },
    component: function component() {
      return import("@/view/pages/admin/seller/sale/SellerOrderDetail.vue");
    }
  }]
}, {
  // the 404 route, when none of the above matches
  path: "/admin/404",
  name: "admin-page-not-authorized",
  component: function component() {
    return import("@/view/pages/error/Error-7.vue");
  }
}];