import jwt from "jsonwebtoken";
var ID_TOKEN_KEY = "id_token";
var ID_REFRESH_TOKEN_KEY = "id_token_refresh";
export var tokenIsExpired = function tokenIsExpired() {
  var decoded = jwt.decode(getRefreshToken());
  return decoded.exp * 1000 < new Date().valueOf();
};
export var getToken = function getToken() {
  if (window.localStorage.getItem(ID_TOKEN_KEY) === "undefined") {
    destroyToken();
    return "";
  }

  return window.localStorage.getItem(ID_TOKEN_KEY);
};
export var getRefreshToken = function getRefreshToken() {
  if (window.localStorage.getItem(ID_REFRESH_TOKEN_KEY) === "undefined") {
    destroyToken();
    return "";
  }

  return window.localStorage.getItem(ID_REFRESH_TOKEN_KEY);
};
export var saveToken = function saveToken(token) {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};
export var saveRefreshToken = function saveRefreshToken(token) {
  window.localStorage.setItem(ID_REFRESH_TOKEN_KEY, token);
};
export var destroyToken = function destroyToken() {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_REFRESH_TOKEN_KEY);
};
export default {
  tokenIsExpired: tokenIsExpired,
  getToken: getToken,
  getRefreshToken: getRefreshToken,
  saveToken: saveToken,
  saveRefreshToken: saveRefreshToken,
  destroyToken: destroyToken
};