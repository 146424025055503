import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.exec.js";
import e from "../utils/format";
export default function a() {
  return {
    validate: function validate(a) {
      if (a.value === "") {
        return {
          valid: true
        };
      }

      var s = Object.assign({}, {
        inclusive: true,
        message: ""
      }, a.options);
      var t = parseFloat("".concat(s.min).replace(",", "."));
      return s.inclusive ? {
        message: e(a.l10n ? s.message || a.l10n.greaterThan.default : s.message, "".concat(t)),
        valid: parseFloat(a.value) >= t
      } : {
        message: e(a.l10n ? s.message || a.l10n.greaterThan.notInclusive : s.message, "".concat(t)),
        valid: parseFloat(a.value) > t
      };
    }
  };
}